<template>
  <v-app-bar id="app-bar" app color="#536DFE" flat dark height="65">
    <v-toolbar-title class="font-weight-bold">
      {{ $route.name }}
    </v-toolbar-title>

    <v-spacer />
    <strong v-html="mail" />
    <menu-right />
  </v-app-bar>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import cost from '@/const/const'
import MenuRight from './MenuRight.vue'

export default {
  name: 'DashboardCoreAppBar',

  components: {
    MenuRight,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      mail: localStorage.getItem(cost.STORAGE_EMAIL),
    }
  },

  computed: {
    ...mapState(['drawer']),
  },

  methods: {
    ...mapMutations({
      setDrawer: 'SET_DRAWER',
    }),
  },
}
</script>
