<template>
  <div class="right-menu">
    <v-menu v-model="showMenu" offset-y>
      <template v-slot:activator="{ on }">
        <v-btn class="btnLocaleActivation right-menu-btn" text v-on="on">
          <v-icon>mdi-account-circle</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(item, i) in menus" :key="`Menu${i}`" active-class="white--text" @click="switchMenu(item.id)">
          <img v-if="item.image != undefined" :src="item.image" width="24" height="24" />
          <v-icon v-if="item.image == undefined">{{ item.icon }}</v-icon>
          <v-list-item-title class="ml-3">
            {{ item.name }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <aliveDateEdit :dialogItems="dialogItems" v-model="showaliveDateEdit" @close="closealiveDateEdit()" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import imageinopay from '../../assets/img/inopei.png'
import imagesmile from '../../assets/img/smile.png'
import cnst from '@/const/const'
import aliveDateEdit from '../../views/alive/aliveDateEdit.vue'

export default {
  components: {
    aliveDateEdit,
  },
  data() {
    return {
      showMenu: false,
      showaliveDateEdit: false,
      menus: [
        {
          id: 'reload',
          name: 'リロード',
          icon: 'mdi mdi-reload',
        },
        {
          id: 'inopay',
          name: 'ダウンロード',
          image: imageinopay,
        },
        {
          id: 'smile',
          name: 'ダウンロード',
          image: imagesmile,
        },
        {
          id: 'alive',
          name: 'ALIVE情報修正',
          icon: 'mdi mdi-account-wrench',
        },
        {
          id: 'logout',
          name: 'ログアウト',
          icon: 'mdi mdi-logout',
        },
        {
          id: 'version',
          name: '　　Ver.' + cnst.VERSION,
          icon: 'mdi-information-circle-outline',
        },
      ],
      dialogItems: {
        aliveId: '',
        alivePassword: '',
      },
    }
  },
  computed: {},
  methods: {
    ...mapActions('login', ['userLogout']),
    switchMenu(id) {
      switch (id) {
        case 'logout':
          this.userLogout()
          break
        case 'alive':
          // this.showaliveDateEdit = true;
          this.aliveEdit()
          break
        case 'inopay':
          window.open('https://inopay-download.s3.ap-northeast-1.amazonaws.com/CQTqESanX6cxCBJ7tz9V6FfyQMxuEix3Ul1Q9jXo3v3hwAnBAYysPATZ2aHS/inopay_download.html')
          break
        case 'smile':
          window.open('https://prod-smileregister-user-download.s3-ap-northeast-1.amazonaws.com/smileregi_download.html')
          break
        case 'reload':
          window.location.reload()
          break
        default:
          break
      }
    },
    //
    aliveEdit() {
      this.dialogItems.aliveId = localStorage.getItem('ALIVE_ID')
      this.dialogItems.alivePassword = localStorage.getItem('ALIVE_PASSWORD')
      this.showaliveDateEdit = true
    },
    closealiveDateEdit() {
      console.log('ポップアップ確認')
      this.showaliveDateEdit = false
    },
    //
  },
}
</script>

<style>
.flag {
  width: 118px !important;
}

.v-list-item {
  min-width: 185px !important;
}

.right-menu {
  height: 100%;
  padding: 5px;
}

.right-menu-btn {
  height: 100% !important;
}
</style>
