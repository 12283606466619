<template>
  <v-row justify="center">
    <v-dialog v-model="value" persistent max-width="500">
      <v-form ref="form">
        <v-card>
          <v-card-title class="text-h5"> ALIVE情報を入力してください </v-card-title>
          <v-card-title class="pt-0 pb-0"> ALIVE情報修正ログインID </v-card-title>
          <v-card-text class="pt-0 pb-0">
            <v-text-field v-model="dialogItems.aliveId" :rules="[rules.required]" outlined dense />
          </v-card-text>
          <v-card-title class="pt-0 pb-0"> ALIVEパスワード </v-card-title>
          <v-card-text class="pt-0 pb-0">
            <v-text-field v-model="dialogItems.alivePassword" :rules="[rules.required]" outlined dense :append-icon="showpwd ? 'mdi-eye' : 'mdi-eye-off'" :type="showpwd ? 'text' : 'password'" @click:append="showpwd = !showpwd" />
          </v-card-text>
          <v-card-title class="pt-0 pb-0"> ALIVEパスワード確認 </v-card-title>
          <v-card-text class="pt-0 pb-0">
            <v-text-field v-model="alivePasswordCheck" :rules="[rules.required]" outlined dense :append-icon="showpwdCheck ? 'mdi-eye' : 'mdi-eye-off'" :type="showpwdCheck ? 'text' : 'password'" @click:append="showpwdCheck = !showpwdCheck" />
          </v-card-text>
          <v-card-text v-if="message" class="pt-0 red--text text-h6">
            {{ message }}
          </v-card-text>
          <v-card-actions>
            <v-btn color="#3f51b5" class="white-text" @click="closeDialog()"> キャンセル </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="#3f51b5" class="white-text" @click="setAliveDate()"> 登録 </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    value: Boolean,
    dialogItems: {},
  },
  data() {
    return {
      alivePasswordCheck: '',
      showpwd: false,
      showpwdCheck: false,
      message: '',
      rules: {
        required: (value) => !!value || '必須入力です',
      },
    }
  },
  methods: {
    setAliveDate() {
      if (!this.$refs.form.validate()) {
        return
      }
      // パスワードとパスワード確認が一致しているか確認
      if (this.dialogItems.alivePassword != this.alivePasswordCheck) {
        this.message = 'パスワードとパスワード確認が一致していません'
        return
      }

      // ローカルストレージに保存
      localStorage.setItem('ALIVE_ID', this.dialogItems.aliveId)
      localStorage.setItem('ALIVE_PASSWORD', this.dialogItems.alivePassword)

      this.$emit('close', 'edit')
    },
    closeDialog() {
      this.$emit('close', 'cancel')
    },
  },
}
</script>
<style>
.white-text {
  color: white !important;
}
</style>
